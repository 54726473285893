import React, { useState } from 'react';
import './Chatbot.css';
import { FaComments, FaTimes } from 'react-icons/fa';

const prompts = {
  "What services do you offer?": "We offer a range of cybersecurity services, including penetration testing, vulnerability assessments, vulnerability management, and more.",
  "How can I contact support?": "You can contact our support team by emailing contactus@triadred.com.",
  "What is your pricing model?": "Our pricing model varies based on the services you need. Please contact us for a detailed quote.",
  "What is penetration testing?": "Penetration testing is a method of evaluating the security of an information system by simulating an attack from malicious outsiders and insiders.",
  "What is a vulnerability assessment?": "A vulnerability assessment is a process of identifying, quantifying, and prioritizing vulnerabilities in a system or network.",
  "How can I protect my business from cyber attacks?": "Implementing strong cybersecurity measures such as firewalls, intrusion detection systems, regular software updates, employee training, along with continuous testing and a maintained cybersecurity program.",
  "What is ransomware?": "Ransomware is a type of malicious software designed to block access to a computer system until a sum of money is paid.",
  "How can I prevent ransomware attacks?": "Regularly back up your data, keep your software updated, use strong passwords, and educate your employees about phishing scams to prevent ransomware attacks."
};

function Chatbot() {
  const [isOpen, setIsOpen] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState('');
  const [currentAnswer, setCurrentAnswer] = useState('');

  const toggleChatbot = () => {
    setIsOpen(!isOpen);
    setCurrentQuestion('');
    setCurrentAnswer('');
  };

  const handlePromptClick = (prompt) => {
    setCurrentQuestion(prompt);
    setCurrentAnswer(prompts[prompt]);
  };

  return (
    <div className="chatbot">
      {isOpen && (
        <div className="chat-window">
          <div className="chat-header">
            <h4>Chat with us</h4>
            <FaTimes onClick={toggleChatbot} />
          </div>
          <div className="chat-body">
            {currentQuestion && (
              <div className="chat-message bot">
                <strong>Question:</strong> {currentQuestion}
              </div>
            )}
            {currentAnswer && (
              <div className="chat-message bot">
                <strong>Answer:</strong> {currentAnswer}
              </div>
            )}
          </div>
          <div className="chat-footer">
            <h5>Select a question:</h5>
            <ul>
              {Object.keys(prompts).map((prompt, index) => (
                <li key={index} onClick={() => handlePromptClick(prompt)}>
                  {prompt}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
      <div className="chat-button" onClick={toggleChatbot}>
        <FaComments />
      </div>
    </div>
  );
}

export default Chatbot;
