import React, { useState, useEffect } from 'react';
import './Home.css';
import { FaShieldAlt, FaLock, FaBug, FaNetworkWired } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import CTAButton from '../CTAButton';
import Chatbot from '../Chatbot'; // Import Chatbot component

function Home() {
  const [scrollDirection, setScrollDirection] = useState(null);
  const [showCookiesPopup, setShowCookiesPopup] = useState(true);
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    let lastScrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const handleScroll = () => {
      const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (currentScrollTop > lastScrollTop) {
        setScrollDirection('down');
      } else {
        setScrollDirection('up');
      }
      lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop; // For Mobile or negative scrolling
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    // Check if cookies have been accepted or denied
    const cookiesAccepted = localStorage.getItem('cookiesAccepted');
    if (cookiesAccepted === 'true' || cookiesAccepted === 'false') {
      setShowCookiesPopup(false);
    }
  }, []);

  const acceptCookies = () => {
    localStorage.setItem('cookiesAccepted', 'true');
    setShowCookiesPopup(false);
  };

  const denyCookies = () => {
    localStorage.setItem('cookiesAccepted', 'false');
    setShowCookiesPopup(false);
  };

  const slides = [
    {
      title: "Initial Compromise (June 2023)",
      description: "Attackers exploited a zero-day vulnerability in the MOVEit Transfer tool, affecting over 200 organizations globally and exposing personal information of up to 17.5 million individuals."
    },
    {
      title: "Discovery (June 2023)",
      description: "The breach was discovered after unusual traffic patterns were detected on the MOVEit Transfer servers, leading to an investigation that uncovered the unauthorized access."
    },
    {
      title: "Public Disclosure (June 2023)",
      description: "MOVEit publicly disclosed the vulnerability, advising all users to apply patches and mitigate the risk by following their security recommendations."
    },
    {
      title: "Response and Mitigation (June - July 2023)",
      description: "Organizations affected by the breach started forensic investigations, patched their systems, and implemented additional security measures to prevent further exploitation."
    },
    {
      title: "Ongoing Investigation (July 2023 - Present)",
      description: "Law enforcement and cybersecurity agencies continue to investigate the breach, working to identify the attackers and recover compromised data."
    }
  ];

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + slides.length) % slides.length);
  };

  const goToSlide = (index) => {
    setCurrentSlide(index);
  };

  return (
    <div className="home">
      <section className={`hero ${scrollDirection}`}>
        <h1 className="hero-title">Triad Red</h1>
        <p className="hero-subtitle">Cybersecurity Consulting</p>
      </section>
      <section className="content">
        <h2 className="did-you-know">Did You Know?</h2>
        <div className="fact-container">
          <FaShieldAlt className="side-icon" />
          <div className="fact-item">
            <p>Cybersecurity threats are evolving rapidly, with new types of malware and attacks emerging daily.</p>
          </div>
          <FaLock className="side-icon" />
        </div>
        <div className="fact-container">
          <FaBug className="side-icon" />
          <div className="fact-item">
            <p>Over 60% of small businesses that experience a cyber attack close within six months.</p>
          </div>
          <FaNetworkWired className="side-icon" />
        </div>
        <div className="fact-container">
          <FaShieldAlt className="side-icon" />
          <div className="fact-item">
            <p>Human error is responsible for 95% of cybersecurity breaches.</p>
          </div>
          <FaLock className="side-icon" />
        </div>
        <div className="fact-container">
          <FaBug className="side-icon" />
          <div className="fact-item">
            <p>A strong cybersecurity program can protect your business from financial losses, reputational damage, and legal repercussions.</p>
          </div>
          <FaNetworkWired className="side-icon" />
        </div>
        <div className="fact-container">
          <FaShieldAlt className="side-icon" />
          <div className="fact-item">
            <p>Implementing multi-factor authentication (MFA) can reduce the risk of unauthorized access by over 99%.</p>
          </div>
          <FaLock className="side-icon" />
        </div>
      </section>
      <section className="data-breach-timeline">
        <h2 className="timeline-title">Lifecycle of a Data Breach: MOVEit Vulnerability</h2>
        <div className="timeline-slider">
          {slides.map((slide, index) => (
            <div key={index} className={`slide ${index === currentSlide ? 'active' : ''}`}>
              <h3>{slide.title}</h3>
              <p>{slide.description}</p>
            </div>
          ))}
          <button className="prev-slide" onClick={prevSlide}>&#8249;</button>
          <button className="next-slide" onClick={nextSlide}>&#8250;</button>
        </div>
        <div className="timeline-dots">
          {slides.map((_, index) => (
            <span
              key={index}
              className={`dot ${index === currentSlide ? 'active' : ''}`}
              onClick={() => goToSlide(index)}
            ></span>
          ))}
        </div>
      </section>
      <section className="enhance-security">
        <div className="enhance-content">
          <h2 className="enhance-title">Enhance Your Business's Security Posture</h2>
          <p className="section-text">
          Cyber attacks are occurring daily with the emergence of new technology, outdated software and poor asset management. 
          Without proper cybersecurity measures, businesses are vulnerable to data breaches, financial loss and reputational damage. 
          Triad Red is dedicated to providing tailored solutions that address these challenges, ensuring your business remains secure. 
          We stay ahead of the latest threats to deliver proactive security measures that protect your digital assets and help maintain your competitive edge
          </p>
        </div>
        <CTAButton />
      </section>
      <section className="actionable-findings">
        <div className="actionable-content">
          <h2 className="actionable-title">Our Commitment to Quality</h2>
          <p className="section-text">
          Say goodbye to over priced penetration testing, no vulnerability management, lack of continuous security scanning, 
          and painfully long PDF reports. We deliver only actionable findings and results that you can immediately 
          use to enhance your security posture. Our focus is on providing clear, concise, and actionable insights that 
          help you understand your vulnerabilities and how to address them effectively
          </p>
        </div>
      </section>
      {showCookiesPopup && (
        <div className="cookies-popup">
          <p>We use cookies to enhance your experience on our website, provide personalized content, and analyze our traffic. By continuing to use our site, you consent to our use of cookies. For more information, please review our <Link to="/privacy-policy">Privacy Policy</Link>.</p>
          <div className="cookies-buttons">
            <button onClick={acceptCookies} className="accept-button">Accept</button>
            <button onClick={denyCookies} className="deny-button">Deny</button>
          </div>
        </div>
      )}
      <Chatbot /> {/* Add Chatbot component here */}
    </div>
  );
}

export default Home;
