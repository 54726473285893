import React from 'react';
import './Blog.css';
import BlogSummary from './BlogSummary';

const Blog = () => {
  const blogPosts = [
    {
      title: 'Ransomware Attacks: A Growing Threat to Small Businesses',
      date: 'January 25, 2024',
      summary: 'In recent years, ransomware attacks have become a significant threat to businesses of all sizes. However, small businesses are particularly vulnerable due to their often limited resources and lack of robust cybersecurity measures. This article delves into the rising trend of ransomware attacks targeting small businesses and offers insights into how these organizations can protect themselves.',
      link: '/blog/ransomware-attacks'
    },
    {
      title: 'Why Every Business Needs a Vulnerability Management Program',
      date: 'February 15, 2024',
      summary: 'In today\'s digital age, vulnerabilities in software and hardware systems can have serious consequences for businesses. This post explores the importance of a vulnerability management program and its benefits.',
      link: '/blog/vulnerability-management'
    },
    {
      title: 'Why a One-Size-Fits-All Cybersecurity Program Doesn\'t Work',
      date: 'June 14, 2024',
      summary: 'The myth of a one-size-fits-all cybersecurity program can leave businesses vulnerable. This article discusses why tailored cybersecurity solutions are essential for effective protection.',
      link: '/blog/custom-cybersecurity'
    }
    // Add more blog summaries here
  ];

  return (
    <div className="blog-page">
      <div className="hero">
        <h1 className="hero-title">Our Blog</h1>
      </div>
      <div className="content-section">
        <div className="content-box">
          {blogPosts.map((post, index) => (
            <BlogSummary key={index} {...post} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Blog;
