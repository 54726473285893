import React from 'react';
import { Link } from 'react-router-dom';
import './BlogSummary.css';

const BlogSummary = ({ title, date, summary, link }) => {
  return (
    <div className="blog-summary">
      <h2>{title}</h2>
      <p className="blog-date">Published on {date}</p>
      <p>{summary}</p>
      <Link to={link} className="read-more">Read more</Link>
    </div>
  );
};

export default BlogSummary;
