import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        const handleLinkClick = () => {
            window.scrollTo(0, 0);
        };

        document.querySelectorAll('a').forEach(link => {
            link.addEventListener('click', handleLinkClick);
        });

        return () => {
            document.querySelectorAll('a').forEach(link => {
                link.removeEventListener('click', handleLinkClick);
            });
        };
    }, []);

    return null;
};

export default ScrollToTop;
