import React from 'react';
import './PrivacyPolicy.css';

function PrivacyPolicy() {
  return (
    <div className="privacy-policy">
      <section className="hero">
        <h1 className="hero-title">Privacy Policy</h1>
      </section>
      <section className="content-section">
        <div className="content-box">
          <p><strong>Last updated:</strong> June 14, 2024</p>
          <h2>Introduction</h2>
          <p>Triad Red, LLC ("we" or "us" or "our") respects the privacy of our users ("user" or "you"). This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website [triadred.com], including any other media form, media channel, mobile website, or mobile application related or connected thereto (collectively, the "Site"). Please read this privacy policy carefully. If you do not agree with the terms of this privacy policy, please do not access the site.</p>
          <h2>Information We Collect</h2>
          <p>We may collect information about you in a variety of ways. The information we may collect on the Site includes:</p>
          <h3>Personal Data</h3>
          <p>Personally identifiable information, such as your name, email address, and any messages you send to us, that you voluntarily give to us when you use our contact form or subscribe to our newsletter.</p>
          <h3>Cookies</h3>
          <p>We may use cookies and similar tracking technologies to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Policy.</p>
          <h2>Use of Your Information</h2>
          <p>Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience. Specifically, we may use information collected about you via the Site to:</p>
          <ul>
            <li>Respond to your inquiries and/or other requests.</li>
            <li>Send you emails regarding your inquiries or other products and services.</li>
            <li>Improve our website to better serve you.</li>
          </ul>
          <h2>Disclosure of Your Information</h2>
          <p>We may share information we have collected about you in certain situations. Your information may be disclosed as follows:</p>
          <h3>By Law or to Protect Rights</h3>
          <p>If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation.</p>
          <h2>Security of Your Information</h2>
          <p>We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.</p>
          <h2>Changes to This Privacy Policy</h2>
          <p>We may update this Privacy Policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal, or regulatory reasons. If we make material changes to this policy, we will notify you here, by email, or by means of a notice on our homepage.</p>
          <h2>Contact Us</h2>
          <p>If you have questions or comments about this Privacy Policy, please contact us at:</p>
          <ul>
          <p>Email: <a href="mailto:contactus@triadred.com">contactus@triadred.com</a></p>
          </ul>
        </div>
      </section>
    </div>
  );
}

export default PrivacyPolicy;
