import React from 'react';
import './Services.css';
import { FaSearch, FaBug, FaNetworkWired, FaDesktop, FaLightbulb, FaCode, FaUserShield, FaShieldAlt, FaEye } from 'react-icons/fa';

const serviceDescriptions = {
  penetrationTesting: {
    title: "Penetration Testing",
    description: "Could a malicious actor actually break into your company’s computer system? If so, how would they likely do it?  We'll work WITH, not AGAINST, your IT staff to determine what vulnerabilities exist in your system and what you can do to fix them. We will conduct an authorized, simulated cyberattack on your organization's infrastructure to identify exploitable vulnerabilities and other weaknesses. Penetration Tests will be performed using the same Tactics, Techniques and Procedures (TTP’s) as current Advanced Persistent Threat (APT) groups and actors.",
  },
  dastScanning: {
    title: "Vulnerability Scanning",
    description: "Triad Red provides less-intrusive Vulnerability Scanning services which offer a wide view of the vulnerabilities that exist within your infrastructure. This service is normally paired with Penetration Testing but can be offered as a service by itself. We offer External, Internal and Web App Vulnerability Scanning for clients who want to maintain a vulnerability management program and remain or work towards compliance with relevant frameworks.",
  },
  adAudits: {
    title: "Active Directory Audits",
    description: "Triad Red's Active Directory Attack Path Audits identify and mitigate potential attack vectors within your Microsoft Active Directory environment. Our reporting will display a clear path from a low level user to domain or enterprise admin, if such a path exists. These audits offer insight into how attackers could move laterally within your network and provide strategies to prevent such movements.",
  },
  riskAssessment: {
    title: "Risk Assessment",
    description: "Does your organization need help identifying, analyzing and evaluating the potential risks to your systems? Our Risk Assessment services help prioritize security efforts and allocate resources effectively to protect against the most significant threats. Triad Red provides comprehensive evaluations to ensure your organization's systems are resilient and prepared for any potential security challenges. We'll help you implement robust risk management strategies to safeguard your assets and maintain business continuity.",
  },
  phishingCampaigns: {
    title: "Phishing Campaigns",
    description: "Phishing attempts have become increasingly sophisticated, with attackers using social engineering tactics to craft emails that appear legitimate. Triad Red's Phishing Campaigns mimic those of modern day threat actors but are designed to test and improve your employees' awareness and response to phishing attempts. We'll provide your organization with a detailed report of user interaction with the phish, allowing for additional employee security awareness training.",
  },
  securityTraining: {
    title: "Security Awareness Training",
    description: "Over 95% of breaches occur because of human error.  Our training material raises awareness of the latest cyber security threats and best practices to better protect your organization. This training helps build a security-conscious culture and empowers staff to act as the first line of defense against cyber threats. Today's threat actors are fluid and adapt to technology as it changes, so we continuously monitor trending vulnerabilities and tailor our training material to educate users about current exploits.",
  },
  webDevelopment: {
    title: "Website Development",
    description: "Whether you need help building your business’s website from scratch or migrating to a modern tech stack, Triad Red provides web development services that focus on security best practices and stylized frameworks. We offer a tailored approach to developing your site with well-documented UI/UX, providing your business with a solid web application to use in a production environment. With our expertise, we'll ensure your application is optimized for performance and scalability, meeting the specific needs of your business.",
  },
  executivePiiReporting: {
    title: "Executive PII Reporting",
    description: "Using a combination of automated scanning tools and manual inspection methods across all our digital assets, Triad Red provides business owners and C-Suite executives detailed assessments showing what Personally Identifiable Information (PII), if any, we’re able to locate on the internet and the dark web. Although remediation for someone’s online presence can be limited, we’ll make recommendations for removing avoidable PII spillage.",
  },
  darkWebMonitoring: {
    title: "Dark Web Monitoring and Reconnaissance",
    description: "Leveraging advanced tools and methodologies to identify stolen data and leaked credentials that have become available on the dark web, we’ll provide you with real-time alerts, detailed threat reports and actionable recommendations to reduce your organization’s digital footprint. Our proactive approach ensures timely mitigation of potential risks, safeguarding your sensitive information and ensuring that leaked credentials are rotated and no longer in use.",
  },
};

function Services() {
  return (
    <div className="services">
      <section className="hero">
        <h1 className="hero-title">Our Services</h1>
      </section>
      <section className="services-content">
        <div className="services-list">
          {Object.keys(serviceDescriptions).map((serviceKey) => (
            <div key={serviceKey} className="service-item">
              <div className="service-icon">
                {serviceKey === 'penetrationTesting' && <FaSearch />}
                {serviceKey === 'dastScanning' && <FaBug />}
                {serviceKey === 'adAudits' && <FaNetworkWired />}
                {serviceKey === 'riskAssessment' && <FaLightbulb />}
                {serviceKey === 'phishingCampaigns' && <FaDesktop />}
                {serviceKey === 'securityTraining' && <FaUserShield />}
                {serviceKey === 'webDevelopment' && <FaCode />}
                {serviceKey === 'executivePiiReporting' && <FaShieldAlt />}
                {serviceKey === 'darkWebMonitoring' && <FaEye />}
              </div>
              <div className="service-content">
                <h3>{serviceDescriptions[serviceKey].title}</h3>
                <p>{serviceDescriptions[serviceKey].description}</p>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}

export default Services;
