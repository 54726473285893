// CTAButton.js
import React from 'react';
import './CTAButton.css';
import { Link } from 'react-router-dom';

const CTAButton = () => {
  return (
    <div className="cta-button-container">
      <Link to="/contact" className="cta-button">
        Request a free Consultation
      </Link>
    </div>
  );
};

export default CTAButton;
