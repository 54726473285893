import React from 'react';
import './About.css';
import { FaShieldAlt, FaUsers, FaCheckCircle } from 'react-icons/fa';

function About() {
  return (
    <div className="about">
      <section className="hero">
        <h1 className="hero-title">About Us</h1>
      </section>
      <section className="about-content">
        <div className="card">
          <div className="card-icon">
            <FaShieldAlt />
          </div>
          <div className="card-content">
            <h2>Our Mission</h2>
            <p>Triad Red is dedicated to safeguarding businesses from digital threats. Our company 
              embodies the core principles of the CIA Triad -Confidentiality, Integrity and Availability- 
              while specializing in offensive security. Our goal is to advance your cybersecurity program
               beyond industry standards.</p>
          </div>
        </div>
        <div className="card">
          <div className="card-icon">
            <FaUsers />
          </div>
          <div className="card-content">
            <h2>Our Team</h2>
            <p>Triad Red specializes in a wide range of security services designed to identify vulnerabilities, 
              mitigate risks and enhance your overall security posture. With years of combined experience 
              in the information security field, our team has provided top-tier cybersecurity 
              solutions to several Fortune 500 companies. Our penetration testing staff has successfully
               completed hundreds of engagements and is currently ranked in the top 2% on the industry’s largest ethical hacking platform.</p>
          </div>
        </div>
        <div className="card">
          <div className="card-icon">
            <FaCheckCircle />
          </div>
          <div className="card-content">
            <h2>Why Choose Us?</h2>
            <p>Choosing Triad Red means partnering with a team genuinely dedicated to your security.
               We deliver tailored solutions for each client, ensuring that you receive services that 
               meet your specific needs and budget. Our comprehensive approach covers all aspects of your organization's
                cybersecurity management program, giving you peace of mind your business is operating securely.</p>
          </div>
        </div>
        <div className="card certification-box">
          <h2 className="certification-title">Our employees hold certifications with the following industry-leading companies:</h2>
          <ul className="certification-list">
            <li>Google</li>
            <li>McAfee</li>
            <li>IBM</li>
            <li>EC-Council</li>
            <li>INE</li>
            <li>Qualys</li>
            <li>AWS</li>
          </ul>
        </div>
      </section>
    </div>
  );
}

export default About;
