import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import logo from './images/logo2.png';
import Home from './components/pages/Home';
import About from './components/pages/About';
import Services from './components/pages/Services';
import Contact from './components/pages/Contact';
import PrivacyPolicy from './components/pages/PrivacyPolicy';
import TermsOfService from './components/pages/TermsOfService';
import Blog from './components/pages/Blog';
import RansomwareAttacks from './components/pages/RansomwareAttacks'; // Import the blog post component
import VulnerabilityManagement from './components/pages/VulnerabilityManagement'; // Import the new blog post component
import CustomCybersecurity from './components/pages/CustomCybersecurity'; // Import the new blog post component
import { FaGithub } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import ScrollToTop from './components/ScrollToTop'; // Import ScrollToTop component

function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <Router>
      <ScrollToTop /> {/* Add ScrollToTop component here */}
      <div className="App">
        <header className="App-header">
          <nav className="navbar">
            <div className="logo-container">
              <Link to="/" onClick={() => setIsMenuOpen(false)}>
                <img src={logo} alt="Company Logo" className="logo" />
              </Link>
            </div>
            <button className={`hamburger-menu ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
              <div className="bar1"></div>
              <div className="bar2"></div>
              <div className="bar3"></div>
            </button>
            <ul className={`nav-menu ${isMenuOpen ? 'open' : ''}`}>
              <li><Link to="/" onClick={toggleMenu}>Home</Link></li>
              <li><Link to="/about" onClick={toggleMenu}>About Us</Link></li>
              <li><Link to="/services" onClick={toggleMenu}>Services</Link></li>
              <li><Link to="/contact" onClick={toggleMenu}>Contact</Link></li>
              <li><Link to="/blog" onClick={toggleMenu}>Blog</Link></li> {/* Add Blog link */}
              <li><Link to="/privacy-policy" onClick={toggleMenu}>Privacy Policy</Link></li>
              <li><Link to="/terms-of-service" onClick={toggleMenu}>Terms of Service</Link></li>
            </ul>
          </nav>
        </header>
        <main className="main-content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/blog" element={<Blog />} /> {/* Add Blog route */}
            <Route path="/blog/ransomware-attacks" element={<RansomwareAttacks />} /> {/* Add full blog post route */}
            <Route path="/blog/vulnerability-management" element={<VulnerabilityManagement />} /> {/* Add new blog post route */}
            <Route path="/blog/custom-cybersecurity" element={<CustomCybersecurity />} /> {/* Add new blog post route */}
          </Routes>
        </main>
        <footer className="footer">
          <div className="footer-content">
            <div className="footer-section links">
              <h2 className="footer-title">Quick Links</h2>
              <ul>
                <li><Link to="/" onClick={() => setIsMenuOpen(false)}>Home</Link></li>
                <li><Link to="/about" onClick={() => setIsMenuOpen(false)}>About Us</Link></li>
                <li><Link to="/services" onClick={() => setIsMenuOpen(false)}>Services</Link></li>
                <li><Link to="/blog" onClick={() => setIsMenuOpen(false)}>Blog</Link></li> {/* Ensure Blog link is here too */}
                <li><Link to="/contact" onClick={() => setIsMenuOpen(false)}>Contact</Link></li>
              </ul>
            </div>
            <div className="footer-section contact">
              <h2 className="footer-title">Contact Us</h2>
              <p><a href="mailto:contactus@triadred.com">contactus@triadred.com</a></p>
              
              <p>(205) 608 9907</p>
              <p>505 20th Steet North </p> 
              <p>Suite 1220 1251 </p>
              <p>Birmingham, AL 35203</p>

            </div>
            <div className="footer-section social">
              <h2 className="footer-title">Follow Us</h2>
              <div className="social-icons">
                {/*<a href="https://x.com/?lang=en" target="_blank" rel="noopener noreferrer"><FaXTwitter /></a>*/}
                <a href="https://github.com/Triad-Red" target="_blank" rel="noopener noreferrer"><FaGithub /></a>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="footer-legal">
              <Link to="/privacy-policy" onClick={() => setIsMenuOpen(false)}>Privacy Policy</Link>
              <Link to="/terms-of-service" onClick={() => setIsMenuOpen(false)}>Terms of Service</Link>
            </div>
            <p>&copy; 2024 Triad Red, LLC All rights reserved.</p>
          </div>
        </footer>
      </div>
    </Router>
  );
}

export default App;
