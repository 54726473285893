import React from 'react';
import './TermsOfService.css';

const TermsOfService = () => {
  return (
    <div className="terms-of-service">
      <div className="hero">
        <h1 className="hero-title">Terms of Service</h1>
      </div>
      <div className="content-section">
        <div className="content-box">
        <p><strong>Last updated:</strong> June 14, 2024</p>
          <section>
            <h2>Introduction</h2>
            <p>Welcome to Triad Red. These terms and conditions outline the rules and regulations for the use of our website.</p>
          </section>
          <section>
            <h2>Intellectual Property Rights</h2>
            <p>Other than the content you own, under these Terms, Triad Red and/or its licensors own all the intellectual property rights and materials contained in this website.</p>
          </section>
          <section>
            <h2>Restrictions</h2>
            <p>You are specifically restricted from all of the following: publishing any website material in any other media without prior permission, selling, sublicensing, and/or otherwise commercializing any website material, publicly performing and/or showing any website material, using this website in any way that is or may be damaging to this website, using this website in any way that impacts user access to this website, etc.</p>
          </section>
          <section>
            <h2>Limitation of liability</h2>
            <p>In no event shall Triad Red, nor any of its officers, directors, and employees, be held liable for anything arising out of or in any way connected with your use of this website.</p>
          </section>
          <section>
            <h2>Governing Law & Jurisdiction</h2>
            <p>These Terms will be governed by and interpreted in accordance with the laws of the State of Alabama, and you submit to the non-exclusive jurisdiction of the state and federal courts located in Alabama for the resolution of any disputes.</p>
          </section>
          <section>
            <h2>Changes to Terms of Service</h2>
            <p>We may update our Terms of Service from time to time. We will notify you of any changes by posting the new Terms of Service on this page.</p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;
