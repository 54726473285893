import React from 'react';
import './Contact.css';
import emailjs from 'emailjs-com';
import { FaEnvelope, FaUser, FaBuilding, FaGlobe, FaPhone, FaComment } from 'react-icons/fa';

function Contact() {
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_ql2qpke', 'template_hcw9sn4', e.target, 'zKW98q6eFbPNq2MtG')
      .then((result) => {
        console.log(result.text);
        alert('Message sent successfully!');
      }, (error) => {
        console.log(error.text);
        alert('An error occurred, please try again.');
      });

    e.target.reset();
  };

  const countries = [
    "United States", "Canada", "United Kingdom", "Australia", "Germany", "France", "Japan", "China", "India", 
    "Brazil", "Russia", "Mexico", "Italy", "Spain", "South Korea", "Netherlands", "Saudi Arabia", "Sweden", 
    "Switzerland", "Turkey", "Argentina", "Austria", "Belgium", "Chile", "Colombia", "Denmark", "Egypt", 
    "Finland", "Greece", "Hong Kong", "Hungary", "Indonesia", "Ireland", "Israel", "Malaysia", "Norway", 
    "New Zealand", "Philippines", "Poland", "Portugal", "Singapore", "South Africa", "Thailand", "United Arab Emirates", 
    "Ukraine", "Vietnam", "Czech Republic", "Romania", "Pakistan", "Bangladesh", "Nigeria", "Kenya", "Ghana", 
    "Sri Lanka", "Myanmar", "Morocco", "Peru", "Venezuela", "Kazakhstan", "Uzbekistan", "Belarus", "Cuba", 
    "Ethiopia", "Algeria", "Tanzania", "Nepal", "Sudan", "Afghanistan", "Uganda", "Mozambique", "Madagascar",
    "Cameroon", "Ivory Coast", "Zambia", "Senegal", "Zimbabwe", "Angola", "Rwanda", "Tunisia", "Somalia", "Jordan",
    "Paraguay", "Libya", "Sierra Leone", "Burundi", "Malawi", "Benin", "South Sudan", "Togo", "Eritrea", "Lesotho",
    "Guinea", "Gambia", "Botswana", "Central African Republic", "Mauritania", "Liberia", "Namibia", "Mali", "Chad",
    "Congo", "Djibouti", "Equatorial Guinea", "Gabon", "Eswatini", "Cape Verde", "Comoros", "Sao Tome and Principe",
    "Seychelles", "Bhutan", "Mongolia", "Maldives", "Papua New Guinea", "Samoa", "Solomon Islands", "Tonga", "Vanuatu"
  ];

  return (
    <div className="contact">
      <section className="hero">
        <h1 className="hero-title">Contact Us</h1>
      </section>
      <section className="contact-content">
        <div className="content-box">
          <h2>Complete the form below and we will be in touch as soon as possible</h2>
          <form className="contact-form" onSubmit={sendEmail}>
            <div className="form-group">
              <label htmlFor="first_name"><FaUser className="form-icon" /> First Name</label>
              <input type="text" id="first_name" name="first_name" required />
            </div>
            <div className="form-group">
              <label htmlFor="last_name"><FaUser className="form-icon" /> Last Name</label>
              <input type="text" id="last_name" name="last_name" required />
            </div>
            <div className="form-group">
              <label htmlFor="company_name"><FaBuilding className="form-icon" /> Company Name</label>
              <input type="text" id="company_name" name="company_name" required />
            </div>
            <div className="form-group">
              <label htmlFor="country"><FaGlobe className="form-icon" /> Country</label>
              <select id="country" name="country" required>
                <option value="">Select your country</option>
                {countries.map(country => (
                  <option key={country} value={country}>{country}</option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="phone"><FaPhone className="form-icon" /> Phone Number</label>
              <input type="text" id="phone" name="phone" required />
            </div>
            <div className="form-group">
              <label htmlFor="reply_to"><FaEnvelope className="form-icon" /> Email</label>
              <input type="email" id="reply_to" name="reply_to" required />
            </div>
            <div className="form-group">
              <label htmlFor="message"><FaComment className="form-icon" /> Message</label>
              <textarea id="message" name="message" rows="5" required></textarea>
            </div>
            <button type="submit" className="submit-button">Send Message</button>
          </form>
        </div>
      </section>
    </div>
  );
}

export default Contact;
